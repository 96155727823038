import React, { useState, useEffect } from 'react';
import {
    Create,
    SimpleForm,
    TextInput,
    SelectInput,
    NumberInput,
    ReferenceInput,
    AutocompleteInput,
    required,
    useRedirect,
    useDataProvider,
    DateTimeInput,
    useGetList,
    CreateBase, Form, Toolbar,
    useNotify
} from 'react-admin';
import { endpoint, wsEndpoint } from '../App';
import { getIconByAction } from './NotificationsList';
import {
    List,
    ListItem,
    ListItemAvatar,
    ListItemIcon,
    ListItemSecondaryAction,
    ListItemText,
    Checkbox,
    Typography,
    Box,
    Icon,
    Paper
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import axios from 'axios';
import { useSound } from 'use-sound';
import DeleteIcon from '@mui/icons-material/Delete';

const Notifications = () => {  
const [notifications, setNotifications] = useState([]);
const [playSound] = useSound(
    '/sounds/notification_sound.mp3',
    { volume: 0.25 }
  );

function wsConnect() {
    var ws = new WebSocket(wsEndpoint);
    ws.onopen = function() {
        console.log('WebSocket OPENED');
        ws.send(JSON.stringify({ id: localStorage.getItem('id'), token: localStorage.getItem('token') }));
    };
    ws.onmessage = function(event) {
        const message = JSON.parse(event.data);
        console.log('Received message:', message);
        
        //setNotifications(prev => [...prev, message.new_val ? message.new_val : message]);
        if(message.openLink){
          goToPage(message.openLink);
        }else{
           // COMMENTED HERE TO REMOVE NOTIFICATIONS
          //setNotifications(prev => [...prev, message.new_val ? message.new_val : message]);
        }
    };
    ws.onclose = function(e) {
      console.log('Socket is closed. Attempting to reconnect...', e.reason);
      wsConnect();
    };
    ws.onerror = function(err) {
      console.error('Socket encountered error: ', err.message, 'Closing socket');
      ws.close();
    };
}

/*setTimeout(function() {
    fetchData();
}, 30000);

useEffect(() => {
    connect();
    fetchData();
}, []);*/

useEffect(() => {
    //if(localStorage.getItem('phone_extension') && localStorage.getItem('phone_extension') !== ''){}
    wsConnect();
    
    fetchData();
    const interval = setInterval(() => {
        fetchData();
    }, 30000);

    return () => clearInterval(interval);
  }, []);

const fetchData = async () => {
    try {
      const token = localStorage.getItem('token'); // Get token from localStorage
      const id = localStorage.getItem('id'); // Get id from localStorage
      const filterParams = {
        manager_account_id: id,
        status: 'unseen',
        date_time_before: true
      }
      const response = await axios.get(
        `${endpoint}/notifications?filter=${encodeURIComponent(JSON.stringify(filterParams))}`,
        {
          headers: {
            Authorization: `${token}` // Set Authorization header with token
          },
        }
      );
      // Check if the response data is different from the current notifications
      const isNewNotification = JSON.stringify(response.data) !== JSON.stringify(notifications) && response.data.length > 0;
      console.log(new Date().toISOString() + " " + JSON.stringify(response.data));
      // Update notifications only if there are new ones
      if (isNewNotification) {
        // COMMENTED HERE TO REMOVE NOTIFICATIONS
        //  setNotifications(response.data);
          // Play notification sound here
         // playSound();
      }
    } catch (error) {
      console.error('Fetch failed:', error);
    }
};

  // Function to handle closing notification
  const handleCloseNotification = async (notif) => {
    try {
        //Если уведомление из базы данных, не информационное
        if(notif.manager_account_id){
        const token = localStorage.getItem('token'); // Get token from localStorage
        const response = await axios.put(
            `${endpoint}/notifications/${notif.id}`,
            { status: 'read' }, // Set status to 'read' for the notification
            {
                headers: {
                    Authorization: `${token}` // Set Authorization header with token
                },
            }
        );
        console.log('Notification status updated:', response.data);
        }
        // Filter out the notification with the given id
        const updatedNotifications = notifications.filter(notification => notification.id !== notif.id);
        // Set the updated array of notifications
        // COMMENTED HERE TO REMOVE NOTIFICATIONS
        //setNotifications(updatedNotifications);
    } catch (error) {
        console.error('Error updating notification status:', error);
    }
  };

    // Function to handle closing notification
    const handleCloseAllNotifications = async () => {
      try {
         const id = localStorage.getItem('id'); // Get id from localStorage
          const token = localStorage.getItem('token'); // Get token from localStorage
          const response = await axios.put(
              `${endpoint}/notifications_erase/${id}`,
              { status: 'read' }, // Set status to 'read' for the notification
              {
                  headers: {
                      Authorization: `${token}` // Set Authorization header with token
                  },
              }
          );
          console.log('Notification status updated:', response.data);
          

          // Set the updated array of notifications
          setNotifications([]);
      } catch (error) {
          console.error('Error updating notification status:', error);
      }
    };

    const goToPage = (link) => {
      window.location.assign(link);
    };

  return (
    notifications.length > 0 ? (
      <div style={{
        position: 'fixed',
        bottom: '50px', 
        left: '50px', 
        zIndex: 999999, 
      }}>
        {notifications.map(notification => (
          <Paper key={notification.id} style={{
            border: '1px solid #ccc',
            borderRadius: '1rem',
            padding: '10px',
            boxShadow: '0 2px 5px rgba(0, 0, 0, 0.2)',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginTop: '0.2em'
          }}
          >
            <Icon sx={{paddingRight: '1rem'}}>{getIconByAction(notification.action)}</Icon>
            <Typography variant="body1" style={{paddingRight: '1rem', cursor: 'pointer'}} onClick={() => goToPage(`/#/requests/${notification.relation_id}/`)}>{notification.history}</Typography>
            <IconButton onClick={() => handleCloseNotification(notification)}>
                <CloseIcon />
            </IconButton>
          </Paper>
        ))}

       {notifications.length > 1 && 
                 <Paper key={228} style={{
                  border: '1px solid #ccc',
                  backgroundColor: 'red',
                  borderRadius: '1rem',
                  padding: '10px',
                  boxShadow: '0 2px 5px rgba(0, 0, 0, 0.2)',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  marginTop: '0.2em'
                }}>

                  <Typography variant="body1" style={{paddingLeft: '1rem', paddingRight: '1rem', fontWeight: 'bold', color: 'white'}}>Закрыть все уведомления</Typography>
                  <IconButton onClick={() => handleCloseAllNotifications()}>
                      <DeleteIcon style={{ color: 'white' }}/>
                  </IconButton>
                </Paper>
       }
      </div>
    ) : null
  );

};

export default Notifications;